export const PixelEvents = {
  ViewContent: 'ViewContent',
  Contact: 'Contact',
  CompleteRegistration: 'CompleteRegistration',
  InitiateCheckout: 'InitiateCheckout',
  Purchase: 'Purchase',
  Subscribe: 'Subscribe',
  AllPurchase: 'AllPurchase',
  OneTimePurchase: 'One-timePurchase',
};
