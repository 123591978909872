import { Box } from '@mui/material'
import Header from './components/Header'
import MainContent from './components/MainContent'
import Footer from './components/Footer'
import PaymentModal from './components/PaymentModal'
import { useEffect } from 'react'
import { pixelTrackStandard, trackEvent } from '@api/requests'
import { useQuizState } from '@providers/QuizProvider/QuizProvider.hooks'
import { wmq } from '@services/magnus'
import { EvTruck } from '@models/events'
import { PixelEvents } from '@constants/events'

function Subscription() {
    const { userLocation } = useQuizState()

    useEffect(() => {
        window.scrollTo(0, 0)
        pixelTrackStandard(PixelEvents.CompleteRegistration)
        trackEvent('sub screen shown', userLocation)
        wmq.track(EvTruck.SubscriptionShown)
    }, [])

    return (
        <Box
            sx={{
                backgroundColor: '#FFF',
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                marginBottom: '112px',
            }}
        >
            <Header />
            <MainContent />
            <Footer />
            <PaymentModal />
        </Box>
    )
}

export default Subscription
