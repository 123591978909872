import React, { useEffect } from 'react'
import { QuizProvider } from '@providers/QuizProvider/QuizProvider'
import { Elements, RecurlyProvider } from '@recurly/react-recurly'
import { PaymentProvider } from '@providers/PaymentProvider/PaymentProvider'
import Router from './Router'
import { BrowserRouter } from 'react-router-dom'
import { usePaymentConfig } from '@providers/PaymentConfigProvider'
import { preloadImage } from '@hooks/useImagePreloader'
import { IMAGE_PATHS, QUIZ_IMAGE_PATHS } from '@utils/imagePathsManual'
import { redirectToNativeBrowser } from '@utils/redirectToNativeBrowser'

export enum Routes {
    MAIN = '/cognifi/',
    QUIZ = '/cognifi/quiz',
    OFFERS = '/cognifi/offers',
    SUBSCRIPTION = '/cognifi/subscription',
    PRIVACY = '/cognifi/privacy',
    TERMS = '/cognifi/terms',
    COOKIE_POLICY = '/cognifi/cookie-policy',
    ACCESS_PRODUCT = '/cognifi/access-product',
    SIGN_IN = '/cognifi/sign-in',
    WEBAPP = '/cognifi/webapp',
    WEBAPP_TESTS = '/cognifi/webapp/iq-tests',
    WEBAPP_GAMES = '/cognifi/webapp/games',
    WEBAPP_COMPARE = '/cognifi/webapp/compare',
    WEBAPP_ARTICLES = '/cognifi/webapp/articles',
    WEBAPP_PROFILE = '/cognifi/webapp/profile',
    WEBAPP_PROFILE_REPORT = '/cognifi/webapp/profile-report',
    WEBAPP_MANAGE_SUBSCRIPTIONS = '/cognifi/webapp/manage-subscriptions',
    UNSUBSCRIBE = '/cognifi/unsubscription',
    UNSUBSCRIBE_EMAIL = '/cognifi/unsubscribe-with-email'
}

function App() {
    console.log('v000010')

    redirectToNativeBrowser()

    const { paymentConfig } = usePaymentConfig()

    useEffect(() => {
        const imagesToLoad = () => {
            switch (true) {
                case window.location.href.includes(Routes.WEBAPP): {
                    return []
                }
                case window.location.href.includes(Routes.SUBSCRIPTION) ||
                    window.location.href.includes(Routes.OFFERS): {
                    return IMAGE_PATHS
                }
                default: {
                    return [...IMAGE_PATHS, ...QUIZ_IMAGE_PATHS]
                }
            }
        }

        const prelodImg = async () => {
            const imagesPromiseList: Promise<any>[] = []

            for (const i of imagesToLoad()) {
                imagesPromiseList.push(preloadImage(i))
            }

            const promiseSettledResult =
                await Promise.allSettled(imagesPromiseList)

            const rejected = promiseSettledResult.find(
                (promise) => promise.status === 'rejected'
            )
            if (rejected) {
                console.error('Some images was not loaded: ', rejected)
            } else {
                // console.error('Images loaded');
            }
        }
        prelodImg()
    }, [])

    const publicKey = String(
        paymentConfig?.recurly?.pk || process.env.REACT_APP_RECURLY_KEY
    )

    return (
        <RecurlyProvider key={publicKey} publicKey={publicKey}>
            <Elements>
                <BrowserRouter>
                    <QuizProvider>
                        <PaymentProvider>
                            <Router />
                        </PaymentProvider>
                    </QuizProvider>
                </BrowserRouter>
            </Elements>
        </RecurlyProvider>
    )
}

export default App
