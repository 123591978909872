import cookie from 'js-cookie';

export const getFacebookClickParameter = () => {
  try {
    const fbclidAsSearchParam = new URLSearchParams(
      window.location.search
    ).getAll('fbclid');

    let _fbp = '';
    let _fbc = '';
    let fbclid = null;

    const cookies = cookie.get(document.cookie);

    if (cookies) {
      _fbp = cookie.get('_fbp') ?? '';
      _fbc = cookie.get('_fbc') ?? '';
      fbclid = cookie.get('fbclid');
      if (!fbclid || fbclid === '') {
        fbclid = new Date().getTime().toString(16);
      }
    }

    const facebookLidParameter =
      fbclid ?? (fbclidAsSearchParam.length !== 0 ? fbclidAsSearchParam : '');

    const time = new Date().getTime();

    if (!_fbc || facebookLidParameter) {
      if (facebookLidParameter === '') {
        _fbc = '';
      } else {
        _fbc = `fb.1.${time}.${facebookLidParameter}`;
      }
    }

    if (!_fbp) {
      _fbp = 'fb.1.' + time + '.' + Math.random().toString().slice(2, 12);
    }

    return { _fbc, _fbp };
  } catch (error) {
    console.error('Could not properly generate _fbc, _fbp', error);
  }
  return { _fbc: '', _fbp: '' };
};

export const getParamsWithDecreasedValue = (params: Record<string, any>) => {
  const decreasedValue = params.value ? Number((Number(params.value) * 0.75).toFixed(2)) : undefined
  return { ...params, value: decreasedValue }
}
