import QuizButton, { ButtonType } from '@components/common/QuizButton'
import { Box, CircularProgress, Modal, Typography } from '@mui/material'
import { usePaymentConfig } from '@providers/PaymentConfigProvider'
import { usePaymentState } from '@providers/PaymentProvider/PaymentProvider.hooks'

function AreYouSureModal({
    isUnlimPack,
    isModalOpen,
    amountToSave,
    onGetClick,
    onSkipClick,
    isLoading,
    subTitle,
    btnText,
    points,
}: {
    isUnlimPack?: boolean
    isModalOpen: boolean
    amountToSave: number
    onGetClick: () => void
    onSkipClick: () => void
    isLoading: boolean
    subTitle: JSX.Element | string
    btnText: string
    points: JSX.Element[]
}) {
    const { showAdditionalOffersPrice, ultPackSalePlan } = usePaymentConfig()
    const { additionalPaymentError } = usePaymentState()

    return (
        <Modal open={isModalOpen}>
            <Box
                sx={{
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'flex-end',
                }}
            >
                <Box
                    sx={{
                        background: '#fff',
                        width: '100%',
                        maxWidth: '500px',
                        padding: '20px',
                        borderRadius: '24px 24px 0 0',
                    }}
                >
                    <Typography
                        sx={{
                            marginBottom: '10px',
                            fontFamily: 'Avenir600',
                            fontSize: '24px',
                            lineHeight: 'normal',
                            color: '#020202',
                            textAlign: 'center',
                        }}
                    >
                        Are you sure?
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: 'Avenir400',
                            fontSize: '16px',
                            lineHeight: 'normal',
                            color: 'rgba(2, 2, 2, 0.50)',
                            textAlign: 'center',
                            '& .black': {
                                color: '#020202',
                                fontFamily: 'Avenir600',
                            },
                            '& .color': {
                                color: '#6639E6',
                                fontFamily: 'Avenir600',
                            },
                        }}
                    >
                        {subTitle}
                    </Typography>
                    {isUnlimPack ? (
                        <>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    gap: '5px',
                                    margin: '20px 0',
                                    div: {
                                        borderRadius: '12px',
                                        padding: '10px',
                                        background: '#F3F2F8',
                                        display: 'flex',
                                        gap: '10px',
                                        alignItems: 'center',
                                    },
                                    '& .pointImg': {
                                        minWidth: '32px',
                                        minHeight: '32px',
                                    },

                                    '& .checkedImg': {
                                        minWidth: '24px',
                                        minHeight: '24px',
                                    },
                                    '& .point': {
                                        width: '100%',
                                        fontFamily: 'Avenir500',
                                        fontSize: '16px',
                                        lineHeight: 'normal',
                                        color: '#020202',
                                    },
                                }}
                            >
                                <Box>
                                    <img
                                        src="/cognifi/svg/offer/p12.svg"
                                        alt="sign"
                                        className="pointImg"
                                    ></img>
                                    <Typography className="point">
                                        Personalized IQ certificate
                                    </Typography>
                                    <img
                                        src="/cognifi/svg/offers_checkmark.svg"
                                        alt="sign"
                                        className="checkedImg"
                                    ></img>
                                </Box>
                                <Box>
                                    <img
                                        src="/cognifi/svg/offer/p13.svg"
                                        alt="sign"
                                        className="pointImg"
                                    ></img>
                                    <Typography className="point">
                                        Extended analysis report
                                    </Typography>
                                    <img
                                        src="/cognifi/svg/offers_checkmark.svg"
                                        alt="sign"
                                        className="checkedImg"
                                    ></img>
                                </Box>
                                <Box>
                                    <img
                                        src="/cognifi/svg/offer/p14.svg"
                                        alt="sign"
                                        className="pointImg"
                                    ></img>
                                    <Typography className="point">
                                        Early access to new games
                                    </Typography>
                                    <img
                                        src="/cognifi/svg/offers_checkmark.svg"
                                        alt="sign"
                                        className="checkedImg"
                                    ></img>
                                </Box>
                                <Box>
                                    <img
                                        src="/cognifi/svg/offer/p15.svg"
                                        alt="sign"
                                        className="pointImg"
                                    ></img>
                                    <Typography className="point">
                                        Offline access to the app
                                    </Typography>
                                    <img
                                        src="/cognifi/svg/offers_checkmark.svg"
                                        alt="sign"
                                        className="checkedImg"
                                    ></img>
                                </Box>
                            </Box>
                            {showAdditionalOffersPrice && (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        gap: '5px',
                                        width: '100%',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        fontFamily: 'Avenir400',
                                        fontWeight: 400,
                                        fontSize: '16px',
                                        color: '#020202',
                                        marginBottom: '15px',
                                        '& .prev_price': {
                                            fontFamily: 'Avenir400',
                                            fontWeight: 400,
                                            fontSize: '16px',
                                            color: 'rgba(47, 42, 42, 0.60)',
                                            lineHeight: 'normal',
                                            textDecoration: 'line-through',
                                            textDecorationColor: '#FF203B',
                                        },
                                        '& .curr_price': {
                                            fontFamily: 'Avenir600',
                                            fontWeight: 600,
                                            fontSize: '16px',
                                            color: '#FF203B',
                                            lineHeight: 'normal',
                                        },
                                        '& .discount': {
                                            fontFamily: 'Avenir600',
                                            fontWeight: 600,
                                            fontSize: '10px',
                                            color: '#FF203B',
                                            lineHeight: 'normal',
                                            display: 'flex',
                                            background:
                                                'rgba(255, 32, 59, 0.10)',
                                            padding: '3px 8px',
                                            borderRadius: '20px',
                                        },
                                    }}
                                >
                                    One time price of
                                    <span className="prev_price">$99.99</span>
                                    <img
                                        src="/cognifi/svg/offers_arrow_micro.svg"
                                        alt="arrow"
                                    ></img>
                                    <span className="curr_price">
                                        ${ultPackSalePlan?.sum}
                                    </span>
                                    <span className="discount">{71}% OFF</span>
                                </Box>
                            )}
                        </>
                    ) : (
                        <Box
                            sx={{
                                background: '#F3F2F8',
                                padding: '20px',
                                borderRadius: '20px',
                                margin: '20px 0',
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '14px',
                                svg: {
                                    minWidth: '32px',
                                    minHeight: '32px',
                                },
                                '& .title': {
                                    fontFamily: 'Avenir400',
                                    fontSize: '14px',
                                    lineHeight: 'normal',
                                    color: '#020202',
                                    span: {
                                        fontWeight: 700,
                                    },
                                    '& .color': {
                                        color: '#E53E3E',
                                    },
                                },
                                div: {
                                    display: 'flex',
                                    gap: '10px',
                                    alignItems: 'center',
                                },
                            }}
                        >
                            {points.map((el, index) => (
                                <Box key={index}>
                                    <img
                                        src="/cognifi/svg/offers_checkmark.svg"
                                        alt="sign"
                                    ></img>
                                    <Typography className="title">
                                        {el}
                                    </Typography>
                                </Box>
                            ))}
                            <Box
                                sx={{
                                    height: '1px',
                                    background: 'rgba(141, 104, 71, 0.20)',
                                    width: '100%',
                                }}
                            />
                            <Box>
                                <img
                                    src="/cognifi/svg/offers_question.svg"
                                    alt="sign"
                                ></img>
                                <Typography className="title">
                                    Are you sure you want to pass up the chance
                                    to{' '}
                                    <span className="color">
                                        save ${amountToSave}?
                                    </span>
                                </Typography>
                            </Box>
                        </Box>
                    )}
                    {additionalPaymentError && (
                        <Typography
                            sx={{
                                margin: '10px 20px',
                                color: '#E03045',
                                textAlign: 'center',
                            }}
                        >
                            {additionalPaymentError}
                        </Typography>
                    )}
                    <QuizButton
                        onClick={onGetClick}
                        variant={ButtonType.MAIN}
                        text=""
                        disabled={isLoading}
                    >
                        {isLoading ? (
                            <CircularProgress
                                size={20}
                                style={{ color: 'white' }}
                            ></CircularProgress>
                        ) : (
                            <>{btnText}</>
                        )}
                    </QuizButton>
                    <Typography
                        onClick={onSkipClick}
                        sx={{
                            cursor: 'pointer',
                            fontFamily: 'Avenir400',
                            fontWeight: 400,
                            fontSize: '14px',
                            lineHeight: 'normal',
                            color: 'rgba(47, 42, 42, 0.60)',
                            textAlign: 'center',
                            marginTop: '15px',
                            textDecoration: 'underline',
                        }}
                    >
                        Skip this offer and proceed further
                    </Typography>
                </Box>
            </Box>
        </Modal>
    )
}

export default AreYouSureModal
