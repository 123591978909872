import React from 'react'
import styled from 'styled-components'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import Header from './components/Header'
import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { Routes } from '../../App'
import { IQReport } from '@models/webapp'
import { WEBAPP_IMAGE_PATHS } from '@utils/imagePathsManual'
import { preloadImage } from '@hooks/useImagePreloader'
import { onAuthStateChanged, signOut } from 'firebase/auth'
import { firebaseAuth } from '@services/firebase'
import { getProfile, getUserSubscriptions } from '@api/requests'
import { useUser } from '@providers/UserProvider'
import { getPathWithSearchParams } from '@utils/ulr'
import { usePaymentConfig } from '@providers/PaymentConfigProvider'
import { startLogoutTimer } from '@utils/logout'
import { getSubscriptionName } from '@utils/common'

const DefaultWrapper = styled.div`
    max-width: 460px;
    margin: auto;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: var(--grey-light-color);
    background-image: url(/cognifi/images/gradient_background.webp);
    background-size: 100% 400px;
    background-repeat: no-repeat;
`

const ProfileWrapper = styled(DefaultWrapper)`
    background-color: white;
    background-image: url(/cognifi/images/gradient_background.webp);
    background-size: 100% 400px;
`

const CompareWrapper = styled(DefaultWrapper)`
    background-color: white;
    background-image: url(/cognifi/images/gradient_background_fullscreen.webp);
    background-size: cover;
`

const ProfileReportWrapper = styled(DefaultWrapper)`
    background-color: white;
`

interface IWebAppContext {
    reportLoading: boolean
    report: IQReport | null
    setReport: React.Dispatch<React.SetStateAction<IQReport | null>>
    setReportLoading: React.Dispatch<React.SetStateAction<boolean>>
    openIqCertificate: boolean
    setOpenIqCertificate: React.Dispatch<React.SetStateAction<boolean>>
    subscriptionId: string | null
    isSubscriptionCanceled: boolean
    setSubscriptionCanceled: (s: boolean) => void
    subscriptionStartedAt: string | null
    userSubscriptionEmail: string | null
    userSubscriptionName: string | null
}

const WebAppContext = createContext<IWebAppContext | null>(null)

export const useWebAppState = () => {
    const context = useContext(WebAppContext)

    if (!context) {
        throw new Error('useWebAppState must be used within the WebAppContext')
    }

    return context
}

export default function WebApp() {
    const [report, setReport] = useState<IQReport | null>(null)
    const [reportLoading, setReportLoading] = useState(false)
    const [openIqCertificate, setOpenIqCertificate] = useState<boolean>(false)
    const { pathname } = useLocation()
    const navigate = useNavigate()
    const { paymentConfig } = usePaymentConfig()
    const { setUser, setProfile } = useUser()
    const [subscriptionId, setSubscriptionId] = useState<string | null>(null)
    const [isSubscriptionCanceled, setSubscriptionCanceled] = useState(false)
    const [subscriptionStartedAt, setSubscriptionStartedAt] = useState<
        string | null
    >(null)
    const [userSubscriptionEmail, setUserSubscriptionEmail] = useState<
        string | null
    >(null)
    const [userSubscriptionName, setUserSubscriptionName] = useState<
        string | null
    >(null)

    const BackgroundWrapper = useMemo(() => {
        switch (pathname) {
            case Routes.WEBAPP_PROFILE: {
                return ProfileWrapper
            }
            case Routes.WEBAPP_COMPARE: {
                return CompareWrapper
            }
            case Routes.WEBAPP_ARTICLES:
            case Routes.WEBAPP_PROFILE_REPORT: {
                return ProfileReportWrapper
            }
            default: {
                return DefaultWrapper
            }
        }
    }, [pathname])

    useEffect(() => {
        const prelodImg = async () => {
            const imagesPromiseList: Promise<any>[] = []

            for (const i of WEBAPP_IMAGE_PATHS) {
                imagesPromiseList.push(preloadImage(i))
            }

            const promiseSettledResult =
                await Promise.allSettled(imagesPromiseList)

            const rejected = promiseSettledResult.find(
                (promise) => promise.status === 'rejected'
            )
            if (rejected) {
                console.error('Some webapp images was not loaded: ', rejected)
            } else {
                console.error('Webapp Images loaded')
            }
        }
        prelodImg()
    }, [])

    useEffect(() => {
        let interval: string | number | NodeJS.Timeout | undefined = undefined
        onAuthStateChanged(firebaseAuth, (user) => {
            if (!user) {
                navigate(getPathWithSearchParams(Routes.SIGN_IN))
            } else {
                interval = startLogoutTimer(() => {
                    signOut(firebaseAuth)
                    setUser(null)
                    navigate(Routes.SIGN_IN)
                })

                const isAppleAuth =
                    user.providerData[0].providerId === 'apple.com'
                const userId = isAppleAuth ? user.providerData[0].uid : user.uid
                const recurlyUri =
                    paymentConfig?.recurly?.uri ??
                    process.env.REACT_APP_RECURLY_URI ??
                    ''

                getUserSubscriptions(userId, recurlyUri)
                    .then(async ({ subscriptions }) => {
                        const sortedByDateSubscriptions = subscriptions?.sort(
                            (a, b) =>
                                new Date(b.started_at).getTime() -
                                new Date(a.started_at).getTime()
                        )

                        const activeMainSubscription =
                            sortedByDateSubscriptions.find((subscription) => {
                                return (
                                    subscription.bundle === 'cognifi' &&
                                    subscription.main_plan &&
                                    subscription.status === 'active'
                                )
                            })
                        const canceledMainSubscription =
                            sortedByDateSubscriptions.find((subscription) => {
                                return (
                                    subscription.bundle === 'cognifi' &&
                                    subscription.main_plan &&
                                    subscription.status === 'canceled'
                                )
                            })

                        const userSubscription =
                            activeMainSubscription ?? canceledMainSubscription

                        if (userSubscription) {
                            setSubscriptionId(userSubscription.id)
                            setSubscriptionStartedAt(
                                userSubscription.started_at
                            )
                            setSubscriptionCanceled(
                                userSubscription.status === 'canceled'
                            )
                            setUserSubscriptionEmail(userSubscription.emails[0])
                            setUserSubscriptionName(
                                getSubscriptionName(userSubscription.plan_code)
                            )
                            user.getIdToken(true)
                                .then((token) => {
                                    getProfile(user.uid, token)
                                        .then((response) => {
                                            setProfile(response)
                                        })
                                        .catch(console.error)
                                })
                                .catch(console.error)
                        } else {
                            navigate(Routes.SIGN_IN)
                            signOut(firebaseAuth)
                            setUser(null)
                        }
                    })
                    .catch(console.error)
            }
        })

        return () => clearInterval(interval)
    }, [])

    const value = {
        report,
        reportLoading,
        setReport,
        setReportLoading,
        openIqCertificate,
        setOpenIqCertificate,
        subscriptionId,
        isSubscriptionCanceled,
        setSubscriptionCanceled,
        subscriptionStartedAt,
        userSubscriptionEmail,
        userSubscriptionName,
    }

    return (
        <WebAppContext.Provider value={value}>
            <BackgroundWrapper>
                <Header />
                <Outlet />
            </BackgroundWrapper>
        </WebAppContext.Provider>
    )
}
