import React, { FormEvent, useEffect, useMemo, useState } from 'react'
import { Box, CircularProgress, Modal, Typography } from '@mui/material'
import QuizButton, { ButtonType } from '../../common/QuizButton'
import { useQuizState } from '@providers/QuizProvider/QuizProvider.hooks'
import {
    CardCvvElement,
    CardMonthElement,
    CardNumberElement,
    CardNumberElementChangeEvent,
    CardYearElement,
} from '@recurly/react-recurly'
import { usePaymentState } from '@providers/PaymentProvider/PaymentProvider.hooks'
import { EMAIL_REGEX } from '@utils/constants'
import { plansUtils } from '@services/planUtils'
import { usePaymentConfig } from '@providers/PaymentConfigProvider'
import { wmq } from '@services/magnus'
import { EvTruck } from '@models/events'

function PaymentModal() {
    const { setQuizData, quizData } = useQuizState()

    const { handleSubmitForm, formRef, paymentFormError, isPaymentLoading } =
        usePaymentState()
    const { selectedPlan } = usePaymentConfig()
    const [isValidCardNumber, setValidCardNumber] = useState(false)
    const [isValidCardMonth, setValidCardMonth] = useState(false)
    const [isValidCardYear, setValidCardYear] = useState(false)
    const [isValidCardCvv, setValidCardCvv] = useState(false)
    const [isValidFirstName, setValidFirstName] = useState(false)
    const [isValidLastName, setValidLastName] = useState(false)
    const [isValidEmail, setValidEmail] = useState(
        Boolean(quizData.email.toLowerCase().match(EMAIL_REGEX))
    )
    const [showErrors, setShowErros] = useState(false)
    const [isDiscoverCard, setDiscoverCard] = useState(false)

    const inputStyle = {
        fontColor: '#020202',
        fontSize: '16px',
        fontFamily: 'Source Sans Pro',
        lineHeight: 'normal',
        fontWeight: '500',
        backgroundColor: 'white',
    }

    const handleSubmitClick = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()

        if (isPaymentLoading) return

        const isValid =
            !isDiscoverCard &&
            isValidEmail &&
            isValidFirstName &&
            isValidLastName &&
            isValidCardNumber &&
            isValidCardMonth &&
            isValidCardYear &&
            isValidCardCvv
        if (isValid) {
            handleSubmitForm(event)
        } else {
            setShowErros(true)
        }
    }

    const selectedPlanUiInfo = useMemo(() => {
        if (!selectedPlan) return null

        const length = selectedPlan.trial_length
            ? selectedPlan.trial_length
            : selectedPlan.length

        const planName = plansUtils.getPlaneName(
            length,
            Boolean(selectedPlan.trial_length)
        )

        const trialValue =
            selectedPlan.trial_length !== null
                ? selectedPlan.trial_sum
                : selectedPlan.sum

        const discountValue = selectedPlan.discount_base_sum

        const dailyPrice = plansUtils.getCurrentPricePerDayForPlan(
            planName,
            trialValue
        )

        return {
            planName,
            discountValue,
            trialValue,
            dailyPrice,
        }
    }, [selectedPlan])

    function checkForDiscoverCard(event: CardNumberElementChangeEvent) {
        const discoverCards = [6011, 644, 645, 646, 647, 648, 649, 65]
        if (event.valid && event.length === 16) {
            const isDiscover = discoverCards.some((cardNumber) => {
                return event.firstSix.startsWith(cardNumber.toString())
            })
            setDiscoverCard(isDiscover)
        } else {
            setDiscoverCard(false)
        }
    }

    useEffect(() => {
        if (quizData.isPaymentModalOpen) {
            wmq.track(EvTruck.ModalPaymentShown)
        }
    }, [quizData.isPaymentModalOpen])

    return (
        <Modal
            open={quizData.isPaymentModalOpen}
            onClose={() => {
                setQuizData((prev) => ({ ...prev, isPaymentModalOpen: false }))
            }}
        >
            <Box
                sx={{
                    height: '100%',
                    display: 'flex',
                    overflow: 'auto',
                    alignItems: 'flex-end',
                    justifyContent: 'center',
                }}
            >
                <Box
                    sx={{
                        overflow: 'auto',
                        marginTop: 'auto',
                        maxWidth: '460px',
                    }}
                >
                    <Box
                        sx={{
                            backgroundColor: '#fff',
                            borderRadius: '20px 20px 0 0',
                            textAlign: 'center',
                            position: 'relative',
                        }}
                    >
                        <img
                            onClick={() => {
                                setQuizData((prev) => ({
                                    ...prev,
                                    isPaymentModalOpen: false,
                                }))
                            }}
                            style={{
                                position: 'absolute',
                                top: '20px',
                                right: '20px',
                                cursor: 'pointer',
                            }}
                            src={'svg/close.svg'}
                            alt="close"
                        ></img>
                        <Typography
                            sx={{
                                color: '#000',
                                fontFamily: 'Avenir600',
                                fontSize: '20px',
                                padding: '15px',
                            }}
                        >
                            Pay with card
                        </Typography>
                        <Box
                            sx={{
                                padding: '0 20px 20px',
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <Box
                                sx={{
                                    background: '#F3F2F8',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    margin: '0 -20px',
                                    padding: '15px 20px',
                                }}
                            >
                                <Typography
                                    sx={{
                                        color: '#020202',
                                        fontFamily: 'Avenir600',
                                        fontSize: '16px',
                                        textAlign: 'left',
                                    }}
                                >
                                    Total due today:
                                </Typography>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            color: '#020202',
                                            fontFamily: 'Avenir600',
                                            fontSize: '16px',
                                            textAlign: 'right',
                                        }}
                                    >
                                        <span
                                            style={{
                                                color: '#E53E3E',
                                                fontFamily: 'Avenir600',
                                                fontSize: '16px',
                                                textDecoration: 'line-through',
                                            }}
                                        >
                                            $9.99
                                            {/* {getPriceWithDiscount(
                        selectedPlan?.trial_sum ?? 0,
                        selectedPlan?.fakeDiscountPercent ?? selectedPlan?.discount_percent ?? 1
                      )} */}
                                        </span>{' '}
                                        ${selectedPlanUiInfo?.trialValue}
                                    </Typography>
                                    <Box
                                        sx={{
                                            padding: '5px 10px',
                                            borderRadius: '20px',
                                            background: '#E53E3E',
                                            width: 'max-content',
                                            display: 'flex',
                                        }}
                                    >
                                        <img
                                            src="svg/fire.svg"
                                            alt="fire"
                                        ></img>
                                        <Typography
                                            sx={{
                                                fontFamily: 'Avenir600',
                                                fontSize: '10px',
                                                color: '#fff',
                                                marginLeft: '6px',
                                            }}
                                        >
                                            You just saved{' '}
                                            {selectedPlan?.fakeDiscountPercent ??
                                                selectedPlan?.discount_percent}
                                            %
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>

                            <form
                                onSubmit={handleSubmitClick}
                                ref={formRef}
                                onChange={() => {
                                    setShowErros(false)
                                }}
                            >
                                <Box
                                    sx={{
                                        margin: '15px 0',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: '10px',
                                        '& .wrapper': {
                                            display: 'flex',
                                            flexDirection: 'column',
                                            width: '100%',
                                        },
                                        '& input': {
                                            width: 'calc(100%)',
                                            borderRadius: '8px',
                                            border: '1px solid #E6E7EB',
                                            paddingLeft: '15px',
                                            height: '48px',
                                            boxSizing: 'border-box',
                                            color: '#020202',
                                            fontFamily: 'Avenir500',
                                            fontSize: '16px',
                                            outline: 'none',
                                            '&:hover': {
                                                outline: 'none',
                                            },
                                        },
                                        '& .input-title': {
                                            color: '#020202',
                                            fontFamily: 'Avenir500',
                                            fontSize: '14px',
                                            textAlign: 'left',
                                            marginBottom: '5px',
                                        },
                                        '& .error': {
                                            width: '100%',
                                            color: '#E03045',
                                            fontFamily: 'Avenir400',
                                            fontSize: '10px',
                                            textAlign: 'left',
                                            marginTop: '5px',
                                        },
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            gap: '10px',
                                        }}
                                    >
                                        <Box className="wrapper">
                                            <Typography className="input-title">
                                                First name
                                            </Typography>
                                            <input
                                                type="text"
                                                data-recurly="first_name"
                                                onChange={(event) => {
                                                    setValidFirstName(
                                                        event.target.value.trim() !==
                                                            ''
                                                    )
                                                }}
                                            ></input>
                                            {showErrors &&
                                                !isValidFirstName && (
                                                    <Typography className="error">
                                                        Fill in the field
                                                    </Typography>
                                                )}
                                        </Box>
                                        <Box className="wrapper">
                                            <Typography className="input-title">
                                                Last name
                                            </Typography>
                                            <input
                                                type="text"
                                                data-recurly="last_name"
                                                onChange={(event) => {
                                                    setValidLastName(
                                                        event.target.value.trim() !==
                                                            ''
                                                    )
                                                }}
                                            ></input>
                                            {showErrors && !isValidLastName && (
                                                <Typography className="error">
                                                    Fill in the field
                                                </Typography>
                                            )}
                                        </Box>
                                    </Box>
                                    <Box className="wrapper">
                                        <Typography className="input-title">
                                            Email
                                        </Typography>
                                        <input
                                            type="email"
                                            value={quizData.email}
                                            onChange={(event) => {
                                                const isValid =
                                                    event.target.value
                                                        .toLowerCase()
                                                        .match(EMAIL_REGEX)
                                                setValidEmail(Boolean(isValid))
                                                setQuizData((prev) => ({
                                                    ...prev,
                                                    email: event.target.value,
                                                }))
                                            }}
                                        ></input>
                                        {showErrors && !isValidEmail && (
                                            <Typography className="error">
                                                Please enter a valid email
                                            </Typography>
                                        )}
                                    </Box>
                                </Box>

                                <Box
                                    sx={{
                                        borderRadius: '12px',
                                        marginBottom: '15px',
                                        '& .input-wrapper': {
                                            background: 'white',
                                            borderRadius: '8px',
                                            maxHeight: '48px',
                                            paddingLeft: '15px',
                                            border: '1px solid #E6E7EB',
                                            '& iframe': {
                                                maxHeight: '48px',
                                            },
                                        },
                                        '& .input-title': {
                                            color: '#020202',
                                            fontFamily: 'Avenir500',
                                            fontSize: '14px',
                                            textAlign: 'left',
                                            marginBottom: '5px',
                                        },
                                        '& .error': {
                                            width: '100%',
                                            color: '#E03045',
                                            fontFamily: 'Avenir400',
                                            fontSize: '10px',
                                            textAlign: 'left',
                                            marginTop: '5px',
                                        },
                                    }}
                                >
                                    <Typography className="input-title">
                                        Card number
                                    </Typography>
                                    <Box className="input-wrapper">
                                        <CardNumberElement
                                            style={{
                                                ...inputStyle,
                                                placeholder: {
                                                    content:
                                                        'XXXX XXXX XXXX XXXX',
                                                    color: 'rgba(144, 140, 137, 0.5)',
                                                },
                                            }}
                                            inputType={'text'}
                                            onChange={(event) => {
                                                setValidCardNumber(event.valid)
                                                checkForDiscoverCard(event)
                                            }}
                                        />
                                    </Box>
                                    {showErrors && !isValidCardNumber && (
                                        <Typography className="error">
                                            Your card number is incomplete
                                        </Typography>
                                    )}
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            gap: '20px',
                                            marginTop: '15px',
                                        }}
                                    >
                                        <Box>
                                            <Typography className="input-title">
                                                Expires on
                                            </Typography>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    gap: '9px',
                                                }}
                                            >
                                                <Box>
                                                    <Box className="input-wrapper">
                                                        <CardMonthElement
                                                            style={{
                                                                ...inputStyle,
                                                                placeholder: {
                                                                    content:
                                                                        'MM',
                                                                    color: 'rgba(47, 42, 42, 0.2)',
                                                                },
                                                            }}
                                                            onChange={(
                                                                event
                                                            ) => {
                                                                setValidCardMonth(
                                                                    event.valid
                                                                )
                                                            }}
                                                        />
                                                    </Box>
                                                </Box>
                                                <Box className="input-wrapper">
                                                    <CardYearElement
                                                        style={{
                                                            ...inputStyle,
                                                            placeholder: {
                                                                content: 'YY',
                                                                color: 'rgba(47, 42, 42, 0.2)',
                                                            },
                                                        }}
                                                        onChange={(event) => {
                                                            setValidCardYear(
                                                                event.valid
                                                            )
                                                        }}
                                                    />
                                                </Box>
                                            </Box>
                                            {showErrors &&
                                                (!isValidCardMonth ||
                                                    !isValidCardYear) && (
                                                    <Typography
                                                        className="error"
                                                        style={{}}
                                                    >
                                                        Your card is expired
                                                    </Typography>
                                                )}
                                        </Box>
                                        <Box>
                                            <Typography className="input-title">
                                                CVC
                                            </Typography>
                                            <Box className="input-wrapper">
                                                <CardCvvElement
                                                    style={{
                                                        ...inputStyle,
                                                        placeholder: {
                                                            content: '•••',
                                                            color: 'rgba(144, 140, 137, 0.5)',
                                                        },
                                                    }}
                                                    onChange={(event) => {
                                                        setValidCardCvv(
                                                            event.valid
                                                        )
                                                    }}
                                                />
                                            </Box>
                                            {showErrors && !isValidCardCvv && (
                                                <Typography className="error">
                                                    Your card’s security code is
                                                    incompete
                                                </Typography>
                                            )}
                                        </Box>
                                    </Box>
                                </Box>
                                {paymentFormError && (
                                    <Typography
                                        sx={{
                                            marginBottom: '20px',
                                            color: '#E03045',
                                            fontFamily: 'Avenir500',
                                            fontSize: '14px',
                                        }}
                                    >
                                        {paymentFormError}
                                    </Typography>
                                )}
                                {isDiscoverCard && (
                                    <Typography
                                        sx={{
                                            marginBottom: '20px',
                                            color: '#E03045',
                                            fontFamily: 'Avenir500',
                                            fontSize: '14px',
                                        }}
                                    >
                                        Discover cards are not accepted.
                                    </Typography>
                                )}

                                <QuizButton
                                    type="submit"
                                    onClick={() => {}}
                                    variant={ButtonType.MAIN}
                                    text={
                                        isPaymentLoading
                                            ? ''
                                            : 'Proceed payment'
                                    }
                                >
                                    {isPaymentLoading ? (
                                        <CircularProgress
                                            style={{ color: 'white' }}
                                        ></CircularProgress>
                                    ) : undefined}
                                </QuizButton>
                            </form>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Modal>
    )
}

export default PaymentModal
