import {
    createContext,
    FC,
    FormEvent,
    ReactNode,
    useEffect,
    useRef,
    useState,
} from 'react'

import { PaymentContextType, PaymentPlanType } from './PaymentProvider.types'
import { useRecurly } from '@recurly/react-recurly'

import { ApplePayError, ApplePayInstance } from '@recurly/recurly-js'
import {
    createSubscriptionNoUser,
    finalizeSubscriptionRecurly,
    pixelTrackStandard,
    saveUserData,
    trackEvent,
} from '@api/requests'
import {
    redirectBackAfterPayment,
    removeRedirectParamsFromQuery,
} from '@utils/payment'
import { Routes } from '../../App'
import { useQuizState } from '../QuizProvider/QuizProvider.hooks'
import { PaymentMethodType } from '../QuizProvider/QuizProvider.types'
import { useNavigate } from 'react-router-dom'
import { delay, getParamByKey } from '@utils/common'
import { getPathWithSearchParams } from '@utils/ulr'
import useUpdateEffect from '../../hooks/useUpdateEffect'
import { useUser } from '../UserProvider'
import {
    getParamsFromSessionStorage,
    setParamsToSessionStorage,
} from '@utils/storage'
import { convertStringToBoolean, getAttribution } from '@utils/attribution'
import {
    isSubscribeRecurlyErrorResponse,
    isSubscribeRecurlySecure3dResponse,
    isSubscribeRecurlySuccessResponse,
} from '@models/recurly'
import { usePaymentConfig } from '@providers/PaymentConfigProvider'
import { PixelEvents } from '@constants/events'
import { firebaseAuth } from '@services/firebase'
import { wmq } from '@services/magnus'
import { EvTruck } from '@models/events'
import { getParamsWithDecreasedValue } from '@utils/facebook'
export const PaymentContext = createContext<PaymentContextType | null>(null)

export const PaymentProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const trickEvent = convertStringToBoolean(
        getParamByKey('event_trick') ?? 'false'
    )

    const trickFinalValue = trickEvent
        ? PixelEvents.Subscribe
        : PixelEvents.Purchase

    const { setQuizData, quizData, userLocation, userIqScore } = useQuizState()

    const {
        paymentConfig,
        selectedPlan,
        isFacebookPurchaseEventMustBePostponed,
        isCognifiPro,
    } = usePaymentConfig()

    const [isReadyAppleButton, setReadyAppleButton] = useState(false)
    const [applePay, setApplePay] = useState<ApplePayInstance | null>(null)
    const recurly = useRecurly()
    const formRef = useRef<HTMLFormElement>(null)
    const [paymentFormError, setPaymentFormError] = useState<string | null>(
        null
    )
    const [applePayError, setApplePayError] = useState<ApplePayError | null>(
        null
    )

    const [error, setError] = useState<null | string>(null)

    const [isPaymentLoading, setPaymentLoading] = useState(false)

    const [isUserSignedIn, setUserSignedIn] = useState(false)

    const [subscriptionUserEmail, setSubscriptionUserEmail] = useState<
        string | null
    >(null)

    const [loading, setLoading] = useState(false)

    const recurlySecureRef = useRef(null)

    const navigate = useNavigate()

    const { user } = useUser()

    const [isAdditionalPaymentLoading, setAdditionalPaymentLoading] =
        useState(false)

    const [additionalPaymentError, setAdditionalPaymentError] = useState('')

    const redirectEmailParameter = getParamByKey('redirectUserEmail')
    const redirectUserEmail =
        redirectEmailParameter !== '' && redirectEmailParameter !== null
            ? window.decodeURIComponent(redirectEmailParameter)
            : null

    useEffect(() => {
        if (selectedPlan) {
            setApplePay(
                recurly.ApplePay({
                    country: 'US',
                    currency: 'USD',
                    label: selectedPlan.name_full ?? '',
                    total: String(selectedPlan?.trial_sum ?? selectedPlan?.sum),
                })
            )
        }
    }, [selectedPlan])

    useEffect(() => {
        applePay?.ready(() => {
            setReadyAppleButton(true)
            setQuizData((prev) => ({
                ...prev,
                selectedPaymentMethod: PaymentMethodType.APPLE,
            }))
        })
    }, [applePay])

    useEffect(() => {
        if (applePay && subscriptionUserEmail !== null) {
            applePay.on('error', function (err) {
                console.error('Apple Pay error', err)
                setApplePayError(err)
                setPaymentLoading(false)
            })

            applePay.on('token', function (token) {
                if (!token) {
                    console.error('token is not defined')
                } else {
                    createSubscriptionNoUser({
                        url: String(
                            paymentConfig?.recurly?.uri ??
                                process.env.REACT_APP_RECURLY_URI
                        ),
                        email: subscriptionUserEmail,
                        plan_id: selectedPlan?.id,
                        token_id: token.id,
                        attribution: getAttribution({
                            ip: userLocation?.ip ?? '',
                            country: userLocation?.country ?? '',
                        }),
                    })
                        .then(async (response) => {
                            if (isSubscribeRecurlyErrorResponse(response)) {
                                console.error(response.error)
                                setApplePayError({
                                    message: response.error,
                                } as ApplePayError)
                                setPaymentLoading(false)
                            } else if (
                                !isSubscribeRecurlySuccessResponse(response)
                            ) {
                                console.error(response)
                            } else {
                                if (!isFacebookPurchaseEventMustBePostponed) {
                                    const eventData = {
                                        email: subscriptionUserEmail,
                                        currency: 'USD',
                                        value:
                                            selectedPlan?.trial_sum ??
                                            selectedPlan?.sum ??
                                            '',
                                        transactionId: response.id,
                                        items: [
                                            {
                                                item_id: selectedPlan?.id,
                                                item_name: selectedPlan?.name,
                                            },
                                        ],
                                    }

                                    pixelTrackStandard(
                                        PixelEvents.Purchase,
                                        eventData
                                    )

                                    if (isCognifiPro) {
                                        pixelTrackStandard(
                                            PixelEvents.AllPurchase,
                                            getParamsWithDecreasedValue(
                                                eventData
                                            )
                                        )
                                    }
                                }

                                if (trickFinalValue) {
                                    trackEvent(
                                        trickFinalValue,
                                        userLocation
                                    ).catch(console.error)
                                }

                                wmq.track(EvTruck.Purchase, {
                                    email: subscriptionUserEmail,
                                    transactionId: response.id,
                                    productId: selectedPlan?.id ?? '',
                                    paymentSystem: 'recurly',
                                    revenue:
                                        selectedPlan?.trial_sum ??
                                        selectedPlan?.sum ??
                                        '',
                                    currency: 'USD',
                                })

                                if (wmq.isInitialized) {
                                    await delay(1500)
                                }

                                redirectBackAfterPayment(
                                    response.uuid,
                                    token.id,
                                    subscriptionUserEmail,
                                    selectedPlan?.id ?? '',
                                    paymentConfig?.recurly?.uri ?? '',
                                    response.account.code,
                                    userIqScore ?? '115'
                                )
                            }
                        })
                        .catch((response) => {
                            const payload: Record<string, any> = {
                                email: subscriptionUserEmail,
                                productId: selectedPlan?.id ?? '',
                                paymentSystem: 'recurly',
                                revenue:
                                    selectedPlan?.trial_sum ??
                                    selectedPlan?.sum ??
                                    '',
                                currency: 'USD',
                            }

                            if (typeof response.error === 'string') {
                                setApplePayError(response.error)
                                payload.error = response.error
                            }

                            wmq.track(EvTruck.PurchaseError, payload)
                        })
                        .finally(() => {
                            setPaymentLoading(false)
                        })
                }
            })
        }
    }, [applePay, subscriptionUserEmail, paymentConfig?.recurly?.uri])

    useUpdateEffect(() => {
        const redirectSubscriptionId = getParamByKey('redirectSubscriptionId')
        const redirectRecurlyUri = getParamByKey('redirectRecurlyUri')
        const redirectIqScore = getParamByKey('redirectIqScore')

        if (user && redirectSubscriptionId && redirectRecurlyUri) {
            setLoading(true)
            setError(null)

            const appleToken = user.isAppleAuth
                ? (user.providerDataId ?? user.uid)
                : user.uid

            finalizeSubscriptionRecurly({
                url: redirectRecurlyUri,
                subscription_id: redirectSubscriptionId,
                email: user.email,
                firebase_id: !user.isAppleAuth ? appleToken : undefined,
                apple_token: user.isAppleAuth ? appleToken : undefined,
                attribution: getAttribution({
                    ip: userLocation?.ip ?? '',
                    country: userLocation?.country ?? '',
                }),
            })
                .then(async (response) => {
                    if (isSubscribeRecurlySecure3dResponse(response)) {
                        /** only for cards **/
                        const risk = recurly.Risk()

                        const threeDSecure = risk.ThreeDSecure({
                            actionTokenId: response.actionTokenId,
                        })

                        threeDSecure.on('token', function (token) {
                            createSubscriptionWithCardToken(token.id).then(
                                () => {
                                    setPaymentLoading(false)
                                }
                            )
                        })

                        threeDSecure.on('error', function (error) {
                            console.error('error', error)
                        })

                        // @ts-ignore
                        threeDSecure.attach(recurlySecureRef.current)

                        return
                    } else if (isSubscribeRecurlySuccessResponse(response)) {
                        let idToken = ''

                        const iqScore = redirectIqScore ?? String(115)

                        try {
                            idToken =
                                (await firebaseAuth.currentUser?.getIdToken(
                                    true
                                )) ?? ''
                        } catch (err) {
                            console.error(err)
                        }

                        await saveUserData({
                            user_id: user.uid,
                            iq_value: iqScore,
                            subscription_id: user.uid,
                            name: user.displayName,
                            email: user.email,
                            idToken: idToken,
                        }).catch((err) => {
                            console.log('saveUserData err', err)
                        })

                        setParamsToSessionStorage({
                            subscriptionId: '',
                            url: '',
                        })
                        setUserSignedIn(true)
                    } else if (isSubscribeRecurlyErrorResponse(response)) {
                        console.error(response.error)
                        setError(response.error)
                    }
                })
                .catch((err) => {
                    if (isSubscribeRecurlyErrorResponse(err)) {
                        setError(err.error)
                    }
                })
                .finally(() => {
                    setLoading(false)
                    removeRedirectParamsFromQuery()
                })
        }
    }, [user])

    function handlePayByApple() {
        if (applePay) {
            setSubscriptionUserEmail(quizData.email)
            wmq.track([EvTruck.ApplePaySubmit, EvTruck.ModalPaymentSubmit])
            applePay.begin(() => {
                setPaymentLoading(true)
            })
        }
    }

    const createSubscriptionWithCardToken = (tokenId: string) =>
        selectedPlan
            ? createSubscriptionNoUser({
                  url: String(
                      paymentConfig?.recurly?.uri ??
                          process.env.REACT_APP_RECURLY_URI
                  ),
                  email: redirectUserEmail ?? quizData.email,
                  plan_id: selectedPlan.id,
                  token_id: tokenId,
                  attribution: getAttribution({
                      ip: userLocation?.ip ?? '',
                      country: userLocation?.country ?? '',
                  }),
              })
                  .then(async (response) => {
                      if (isSubscribeRecurlyErrorResponse(response)) {
                          console.error(response.error)
                          setPaymentFormError(response.error)
                          setPaymentLoading(false)
                      } else if (!isSubscribeRecurlySuccessResponse(response)) {
                          console.error(response)
                      } else {
                          if (!isFacebookPurchaseEventMustBePostponed) {
                              const eventData = {
                                  email: redirectUserEmail ?? quizData.email,
                                  currency: 'USD',
                                  value:
                                      selectedPlan?.trial_sum ??
                                      selectedPlan?.sum ??
                                      '',
                                  transactionId: response.id,
                                  items: [
                                      {
                                          item_id: selectedPlan?.id,
                                          item_name: selectedPlan?.name,
                                      },
                                  ],
                              }

                              pixelTrackStandard(
                                  PixelEvents.Purchase,
                                  eventData
                              )

                              if (isCognifiPro) {
                                  pixelTrackStandard(
                                      PixelEvents.AllPurchase,
                                      getParamsWithDecreasedValue(eventData)
                                  )
                              }
                          }

                          if (trickFinalValue) {
                              trackEvent(trickFinalValue, userLocation).catch(
                                  console.error
                              )
                          }

                          wmq.track(EvTruck.Purchase, {
                              email: redirectUserEmail ?? quizData.email,
                              transactionId: response.id,
                              productId: selectedPlan?.id ?? '',
                              paymentSystem: 'recurly',
                              revenue:
                                  selectedPlan?.trial_sum ??
                                  selectedPlan?.sum ??
                                  '',
                              currency: 'USD',
                          })

                          setParamsToSessionStorage({
                              tokenId: tokenId,
                              iqScore: userIqScore ?? '115',
                              url:
                                  paymentConfig?.recurly?.uri ??
                                  process.env.REACT_APP_RECURLY_URI,
                              subscriptionId: response.uuid,
                              accountCode: response.account.code,
                              attribution: JSON.stringify(
                                  getAttribution({
                                      country: userLocation?.country ?? '',
                                      ip: userLocation?.ip ?? '',
                                  })
                              ),
                          })

                          if (getParamByKey('redirectDomain')) {
                              if (wmq.isInitialized) {
                                  await delay(1500)
                              }

                              redirectBackAfterPayment(
                                  response.uuid,
                                  tokenId,
                                  quizData.email,
                                  selectedPlan.id ?? '',
                                  paymentConfig?.recurly?.uri ?? '',
                                  response.account.code,
                                  userIqScore ?? '115'
                              )
                          } else {
                              navigate(getPathWithSearchParams(Routes.OFFERS))
                          }
                      }
                  })
                  .catch((response) => {
                      const payload: Record<string, any> = {
                          email: redirectUserEmail ?? quizData.email,
                          productId: selectedPlan?.id ?? '',
                          paymentSystem: 'recurly',
                          revenue:
                              selectedPlan?.trial_sum ??
                              selectedPlan?.sum ??
                              '',
                          currency: 'USD',
                      }

                      if (typeof response.error === 'string') {
                          setPaymentFormError(response.error)
                          payload.error = response.error
                      }

                      wmq.track(EvTruck.PurchaseError, payload)
                  })
            : Promise.resolve()

    function handleSubmitForm(event: FormEvent<HTMLFormElement>) {
        event.preventDefault()
        if (formRef.current) {
            setPaymentLoading(true)
            recurly.token(formRef.current, (err, token) => {
                if (err) {
                    console.error(err)
                    setPaymentFormError(err.message)
                    setPaymentLoading(false)
                } else {
                    setParamsToSessionStorage({
                        recurlyToken: token.id,
                        userEmail: quizData.email,
                    })

                    wmq.track([EvTruck.CardSubmit, EvTruck.ModalPaymentSubmit])

                    createSubscriptionWithCardToken(token.id).then(() => {
                        setPaymentLoading(false)
                    })
                }
            })
        }
    }

    const handleAdditionalOfferAdd = (
        additionalPlan: PaymentPlanType,
        onSuccess: () => void,
        isSkipTrialPlan?: boolean
    ) => {
        setAdditionalPaymentError('')
        setAdditionalPaymentLoading(true)
        const sessionPaymentParams = getParamsFromSessionStorage([
            'recurlyToken',
            'userEmail',
            'accountCode',
        ])

        const body = {
            url: String(
                paymentConfig?.recurly?.uri ?? process.env.REACT_APP_RECURLY_URI
            ),
            email: redirectUserEmail ?? sessionPaymentParams.userEmail,
            plan_id: additionalPlan.id,
            token_id:
                getParamByKey('redirectTokenId') ??
                sessionPaymentParams.recurlyToken,
            account:
                getParamByKey('redirectAccount') ??
                sessionPaymentParams.accountCode,
            attribution: getAttribution({
                ip: userLocation?.ip ?? '',
                country: userLocation?.country ?? '',
            }),
        }

        createSubscriptionNoUser(body)
            .then((response) => {
                if (isSubscribeRecurlyErrorResponse(response)) {
                    setAdditionalPaymentError(response.error)
                } else if (!isSubscribeRecurlySuccessResponse(response)) {
                    console.error(response)
                } else {
                    if (isCognifiPro && !isSkipTrialPlan) {
                        const eventData = {
                            email:
                                redirectUserEmail ??
                                sessionPaymentParams.userEmail,
                            currency: 'USD',
                            value:
                                additionalPlan.trial_sum ?? additionalPlan.sum,
                            transactionId: response.id,
                            items: [
                                {
                                    item_id: additionalPlan.id,
                                    item_name: additionalPlan.name,
                                },
                            ],
                        }
                        pixelTrackStandard(
                            PixelEvents.AllPurchase,
                            getParamsWithDecreasedValue(eventData)
                        )
                        pixelTrackStandard(
                            PixelEvents.OneTimePurchase,
                            getParamsWithDecreasedValue(eventData)
                        )
                    }
                    trackEvent(
                        `Purchase ${additionalPlan.name}`,
                        userLocation
                    ).catch(console.error)

                    wmq.track(EvTruck.AdditionalPurchase, {
                        email:
                            redirectUserEmail ?? sessionPaymentParams.userEmail,
                        transactionId: response.id,
                        productId: additionalPlan?.id ?? '',
                        paymentSystem: 'recurly',
                        revenue:
                            additionalPlan?.trial_sum ??
                            additionalPlan?.sum ??
                            '',
                        currency: 'USD',
                    })

                    onSuccess()
                }
            })
            .catch((error) => {
                const payload: Record<string, any> = {
                    email: redirectUserEmail ?? sessionPaymentParams.userEmail,
                    productId: additionalPlan?.id ?? '',
                    paymentSystem: 'recurly',
                    revenue:
                        additionalPlan?.trial_sum ?? additionalPlan?.sum ?? '',
                    currency: 'USD',
                }

                console.error(error)

                const errorMessage =
                    'error' in error ? error.error : JSON.stringify(error)

                payload.error = errorMessage

                wmq.track(EvTruck.AdditionalPurchaseError, payload)

                trackEvent(
                    `Purchase error ${additionalPlan.name}`,
                    userLocation
                ).catch(console.error)

                setAdditionalPaymentError(errorMessage)
            })
            .finally(() => {
                setAdditionalPaymentLoading(false)
            })
    }

    const value: PaymentContextType = {
        isReadyAppleButton,
        handlePayByApple,
        handleSubmitForm,
        paymentFormError,
        applePayError,
        formRef,
        isPaymentLoading,
        loading,
        paymentConfig,
        error,
        isUserSignedIn,
        handleAdditionalOfferAdd,
        isAdditionalPaymentLoading,
        setAdditionalPaymentLoading,
        additionalPaymentError,
        setAdditionalPaymentError,
    }

    return (
        <PaymentContext.Provider value={value}>
            {children}
        </PaymentContext.Provider>
    )
}
